mtl.alloy.factory['faq-toggle'] = (function($) {
    'use strict';

	return function(element, options) {

		var $element = $(element);

		var $question = $($element).find('.faq-container-category-wrapper__question')
		var $button = $($element).find('.faq-container-category-wrapper__question-button')
		var $answer = $($element).find('.faq-container-category-wrapper__answer')

		$($question).click(function(){

			if($($answer).is(":visible"))
			{
    			$($button.text('+'));
			}
			else{
    			$($button.text('-'));
			}

			$($answer).stop().slideToggle(250);

		});
	};
}(jQuery));