;(function($) {
	'use strict';

	var app = window.app = (window.app || {});

	var container = null;


	/**
	 * Creates and returns SVG icon element.
	 */
	app.svgicon = function(icon, options) {

		if (typeof options === 'string') {
			options = {
				className: options
			};
		}
		else {
			options = options || {};
		}

		icon = (icon + '').replace(/\//g, '__');

		var path = options.path || app.svgicon.defaultPath;

		var className = 'icon';
		if (options.className) {
			className += ' ' + options.className;
		}

		var parts = icon.split(/__/g);
		for (var i = 0, ix = parts.length; i < ix; i++) {
			className += ' icon--' + parts.slice(0, i + 1).join('__');
		}

		var href = path + '#' + icon;

		if (!container) {
			container = document.createElement('div');
		}

		container.innerHTML =
			'<svg class focusable="false">' +
				'<use xmlns:xlink="http://www.w3.org/1999/xlink"></use>' +
			'</svg>'
		;

		var element = container.childNodes[0];
		element.setAttribute('class', className);
		element.childNodes[0].setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', href);
		return element;

	};


	// Configuration
	app.svgicon.defaultPath = '/build/icons/icons.svg'; // TODO: Don't hardcode this

}(jQuery));
