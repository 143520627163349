mtl.alloy.factory['homepage-slider'] = (function($) {
    'use strict';

	return function(element, options) {

		$(document).ready(function() {
			
			var $element = $(element);			

			$($element).slick({
				lazyLoad: 'ondemand',
				slidesToShow: 1,
				slidesToScroll: 1,
				autoplay: true,
				autoplaySpeed: 3000,
				nextArrow:'.next',
				prevArrow:'.prev',
			});
		});
	};
}(jQuery));