mtl.alloy.factory['burger-menu'] = (function($) {
    'use strict';

	return function(element, options) {

		$(".menu-burger, .menu-close").click(function(){

			$(".offset-canvas").stop().slideToggle();

			$('.menu-burger').toggle();
			$('.menu-close').toggle();
			$('html, body').toggleClass("noscroll"); 

		});
	};
}(jQuery));