mtl.alloy.factory['search-toggle'] = (function($) {
    'use strict';

	return function(element, options) {

		var $element = $(element);
		var $search = $('.layout-header-nav-search');

		$($element).click(function(){
			
			if($($search).is(":visible"))
			{
    			$($element.css('color', 'unset'));
			}
			else{
    			$($element.css('color', '#C42E2D'));
			}			
			
			$($search).slideToggle();	

		});
	};
}(jQuery));