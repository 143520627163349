mtl.alloy.factory['credibility-bar'] = (function($) {
    'use strict';

	return function(element, options) {
		$(document).ready(function() {

			if ( $(window).width() < 1024 ) {
				initializeSlider();
			}
			
			$(window).resize(function(){
				if ( $(window).width() < 1024 ) {
					initializeSlider();
				}
				else{
					if( $('.credibility-bar .slider').hasClass('slick-initialized') ){
						$('.credibility-bar .slider').slick('unslick');
					}
				}
				
			});

			function initializeSlider(){
				$('.credibility-bar .slider').slick({
					slidesToShow: 1,
					slidesToScroll: 1,
					autoplay: true,
					adaptiveHeight: false,
					speed:500,
					autoplaySpeed: 3000,
					arrows: false,
				});
			}

		});

	};
}(jQuery));