window.cookiePrompt = (function($) {
	'use strict';
	/* globals Cookies */

	// Cookie prompt module
	var cookiePrompt = {};

	/**
	 * Set the default options.
	 */
	cookiePrompt.options = (function() {

		var infoIcon =
			'<svg focusable="false" width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1152 1376v-160q0-14-9-23t-23-9h-96v-512q0-14-9-23t-23-9h-320q-14 0-23 9t-9 23v160q0 14 9 23t23 9h96v320h-96q-14 0-23 9t-9 23v160q0 14 9 23t23 9h448q14 0 23-9t9-23zm-128-896v-160q0-14-9-23t-23-9h-192q-14 0-23 9t-9 23v160q0 14 9 23t23 9h192q14 0 23-9t9-23zm640 416q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"/></svg>'
		;

		var tickIcon =
			'<svg focusable="false" width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1671 566q0 40-28 68l-724 724-136 136q-28 28-68 28t-68-28l-136-136-362-362q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 295 656-657q28-28 68-28t68 28l136 136q28 28 28 68z"/></svg>'
		;

		var message = 'By continuing to browse this website you agree to <a href="%%privacyLink%%">our use of&nbsp;cookies</a>';

		var template =
			'<div id="cookie-prompt">' +
				'<p>' +
					'%%infoIcon%%' +
					'%%message%%' +
				'</p>' +
				'<button name="accept">' +
					'%%tickIcon%%' +
					'Accept' +
				'</button>' +
			'</div>'
		;

		return {
			openAfter: 1000,
			autoAccept: true,
			infoIcon: infoIcon,
			tickIcon: tickIcon,
			message: message,
			template: template,
			privacyLink: '/cookie-policy',
			cookieName: 'eu-accept-cookies'
		};

	}());


	/**
	 * Create a prompt element.
	 */
	function createElement() {

		var options = cookiePrompt.options;

		var template = options.template;
		template = template.replace(/%%message%%/g, options.message);
		template = template.replace(/%%infoIcon%%/g, options.infoIcon);
		template = template.replace(/%%tickIcon%%/g, options.tickIcon);
		template = template.replace(/%%privacyLink%%/g, options.privacyLink);

		var $element = $($.parseHTML(template)[0]);

		$element
			.on('click', 'button[name="accept"]', accept)
			.on('click', 'button[name="decline"]', decline)
		;

		return $element;

	}


	/**
	 * Open the pmrompt.
	 */
	function open() {

		var $element = $('#cookie-prompt');

		if ($element.length === 0) {

			createElement()
				.appendTo(document.body)
				.delay(50)
				.queue(function(next) {
					$(this).addClass('is-active');
					next();
				})
			;

		}

		else {

			$element
				.stop(true, false)
				.removeClass('is-closing')
				.addClass('is-active')
			;

		}

	}


	/**
	 * Close the prompt.
	 */
	function close() {

		var $element = $('#cookie-prompt');

		if ($element.length === 0 || $element.hasClass('is-closing')) {
			return;
		}

		$element
			.stop(true, false)
			.addClass('is-closing')
			.delay(1000)
			.queue(function(next) {
				$element.remove();
				next();
			})
		;

	}


	/**
	 * Test whether the prompt is open.
	 */
	function isOpen() {

		return ($('#cookie-prompt').length > 0);

	}


	/**
	 * Accept the cookie policy.
	 */
	function accept(noClose) {

		Cookies.set(cookiePrompt.options.cookieName, '1', {
			'expires'	: 365,
			'path'		: '/'
		});

		if (noClose !== false) {
			close();
		}

	}


	/**
	 * Decline the cookie policy.
	 */
	function decline(noClose) {

		if (noClose !== false) {
			close();
		}

	}


	/**
	 * Test whether the cookie policy has been accepted.
	 */
	function hasAccepted() {

		return !!Cookies.get(cookiePrompt.options.cookieName);

	}


	/**
	 * Test whether the cookie policy has not been accepted
	 */
	function notAccepted() {

		return !hasAccepted();

	}


	/**
	 * Automatically open the prompt.
	 */
	function autoOpen() {

		if (hasAccepted()) {
			return;
		}

		if (cookiePrompt.options.autoAccept) {
			accept(false);
		}

		open();

	}


	// On document ready...
	$(document).ready(function() {

		if (hasAccepted()) {
			accept(false);
		}

		else if (cookiePrompt.options.openAfter) {
			setTimeout(autoOpen, cookiePrompt.options.openAfter);
		}

	});


	// Return public API
	return $.extend(cookiePrompt, {
		isOpen: isOpen,
		open: open,
		close: close,
		hasAccepted: hasAccepted,
		accept: accept,
		decline: decline
	});

}(jQuery));
