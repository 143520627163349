mtl.alloy.factory['mobile-subcategory'] = (function($) {
    'use strict';

	return function(element, options) {

		$(element).click(function(){
			
			var $menuId = $(element).data("menu-id");

	    	var $menuElement = "#"+$menuId;
	    	var $category = $('.offset-canvas-category');

    		$($menuElement).toggle();
    		$($category).toggle();
		});
	};
}(jQuery));